import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/page-layout.js";
import { format } from 'date-fns';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const SiteMetaData = makeShortcode("SiteMetaData");
const Fragment = makeShortcode("Fragment");
const Heading = makeShortcode("Heading");
const Text = makeShortcode("Text");
const AspectRatio = makeShortcode("AspectRatio");
const Image = makeShortcode("Image");
const Box = makeShortcode("Box");
const SourceTags = makeShortcode("SourceTags");
const Flex = makeShortcode("Flex");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Grid sx={{
      mb: 3
    }} mdxType="Grid">
      <SiteMetaData mdxType="SiteMetaData">
  {siteMetadata => {
          const {
            name,
            description,
            siteImage
          } = siteMetadata;
          return <Fragment mdxType="Fragment">
        <Heading variant="styles.h1" mdxType="Heading">{name}</Heading>
        <Text style={{
              "textAlign": "center"
            }} mdxType="Text">{description}</Text>
        <AspectRatio ratio={16 / 9} mdxType="AspectRatio">
          <Image src={siteImage} sx={{
                objectFit: 'cover',
                width: '100%',
                height: '100%'
              }} alt="Banner of Cosmopolitan in Las Vegas" mdxType="Image" />
        </AspectRatio>
      </Fragment>;
        }}
      </SiteMetaData>
      <p>{`Hi! My name is Luciano Remes. I love doing Security and Systems Research,
specifically relating to fuzzers and distributed systems. Currently I'm a
Software Engineer at `}<strong parentName="p">{`Palantir Technologies`}</strong>{`.`}</p>
      <p>{`Check out my `}<a parentName="p" {...{
          "href": "/about/"
        }}>{`about`}</a>{` page to learn a bit more about me. My blog
`}<a parentName="p" {...{
          "href": "/posts/"
        }}>{`posts`}</a>{` has my thoughts and experiences on a variety of tech related
topics like internships, programming languages, and research. If you'd like to
stay up to date on what I'm posting, I have an `}<a parentName="p" {...{
          "href": "/rss.xml"
        }}>{`RSS`}</a>{` feed for my
blogs, and you can follow my socials below.`}</p>
    </Grid>
    <h2><a parentName="h2" {...{
        "href": "#recs"
      }}>{`Tech Recommendations of 2024`}</a>{`:`}</h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://github.com/ynqa/jnv"
        }}>{`jnv`}</a>{`:
I use `}<a parentName="li" {...{
          "href": "https://jqlang.github.io/jq/"
        }}>{`jq`}</a>{` a lot at work, so I've found myself
wanting to do more comprehensive searches. Jnv is a cool little cli util that
will interactively query a json object using jq. While jnv is still an early
stage tool, it seems promising.`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://tailscale.com/"
        }}>{`Tailscale`}</a>{`
Tailscale is essentially a new networking layer that allows you to connect
servers and create arbitrary networks between machines. And yes, that means
you can make a VPN with it. Super easy and it costs nothing.`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://fly.io/"
        }}>{`Fly.io`}</a>{`
Fly is a IaaS cloud company with a clean CLI util that fully integrates with
their systems, it makes it exteremely easy to spin up new infrastructure with
a simple script and manage it all from your command-line. The pricing is
decent for its services.`}</li>
    </ol>
    <Grid sx={{
      gridTemplateColumns: ['1fr', '1fr', '1fr 1fr']
    }} mdxType="Grid">  
      <Box mdxType="Box">
        <h2><a parentName="h2" {...{
            "href": "#socials"
          }}>{`Socials`}</a></h2>
        <h4>{`cybergenik `}{`[at]`}{` gmail `}{`[dot]`}{` com`}</h4>
        <h3><a parentName="h3" {...{
            "href": "https://twitter.com/cybergenik"
          }}>{`Twitter`}</a></h3>
        <h3><a parentName="h3" {...{
            "href": "https://discuss.systems/@lremes"
          }}>{`Mastodon`}</a></h3>
        <h3><a parentName="h3" {...{
            "href": "https://github.com/Cybergenik/"
          }}>{`Github`}</a></h3>
        <h3><a parentName="h3" {...{
            "href": "https://www.linkedin.com/in/lremes/"
          }}>{`LinkedIn`}</a></h3>
      </Box>
      <Box mdxType="Box">
        <h2>{`Top blog tags`}</h2>
        <SourceTags filter="posts" mdxType="SourceTags">
  {tags => <Flex sx={{
            flexDirection: 'column'
          }} mdxType="Flex">
      {tags.sort((a, b) => b.count - a.count).splice(0, 5).map((tag, index) => {
              const {
                name,
                count,
                percent
              } = tag;
              return <Box key={index} mdxType="Box">
              {`${name}: ${count}`}
              <Box mb={2} bg='muted' sx={{
                  width: `${percent}%`,
                  height: 4
                }} mdxType="Box" />
            </Box>;
            })}
    </Flex>}
        </SourceTags>
      </Box>
    </Grid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      